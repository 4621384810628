import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Dropdown,
  FormLabel,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { MainContext } from "../../App";

import ChooseCopyOrNew from "../../components/captureGrades/modals/ChooseCopyOrNew";
import AddCriterian from "../../components/captureGrades/modals/AddCriterian";
import CopyCriterian from "../../components/captureGrades/modals/CopyCriterian";
import {
  GetCriteriasByClass,
  GetCriteriasClasses,
  GetTeacherClasses,
  GetStudentClass,
  SaveStudentsScore,
} from "../../api/class";
import {
  LoadingExtraLink,
  LoadingTools,
} from "../../components/lazyLoading/LazyLoading";
import {
  getUrlParamValue,
  goToHref,
  removeUrlParams,
} from "../../components/global/GlobalTools";
import ModalSuccess from "../../components/global/modals/ModalSuccess";
const customStyles = {
  baseContainers: {
    backgroundColor: "rgb(255, 255, 255)",
  },
  firstCriterion: {
    border: "solid 1px #ececee",
    minHeight: "35px",
    verticalAlign: "middle",
    paddingBottom: "10px",
  },
  criterionBox: {
    border: "solid 1px #ececee",
    borderTop: "none",
    minHeight: "35px",
    paddingBottom: "10px",
  },
  brokenText: {
    whiteSpace: "nowrap",
    overflow: "clip",
    textOverflow: "ellipsis !important",
    maxWidth: "80%",
  },
  // Styles for the table of qualifications
  studentTitleNameBox: {
    border: "solid 1px #ececee",
    height: "41px",
    verticalAlign: "middle",
    paddingTop: "3px",
  },
  studentNameBox: {
    border: "solid 1px #ececee",
    borderTop: "none",
    height: "41px",
    verticalAlign: "middle",
  },
  criterionTitleBox: {
    border: "solid 1px #ececee",
    borderLeft: "none",
    height: "41px",
    verticalAlign: "middle",
    minWidth: "fit-content",
    // width: "max-content",
    textAlign: "-moz-center",
    paddingTop: "10px",
  },
  scoreBox: {
    border: "solid 1px #ececee",
    borderLeft: "none",
    borderTop: "none",
    textAlign: "-moz-center",
    height: "41px",
    verticalAlign: "middle",
    // minWidth: "100px",
    // width: "100px",
  },
};

const CaptureGradesView = () => {
  const [t] = useTranslation(["global", "captureGrades"]);
  const { permissions } = useContext(MainContext);
  const history = useHistory();
  const [criterionList, setCriterionList] = useState([]);
  const [studentList, setStudentListCriterionList] = useState([]);
  const [studentsQualificationProcess, setStudentsQualificationProcess] =
    useState([]);
  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - 300
  );
  const [isLoading, setIsLoading] = useState(true);
  const [allClasses, setAllClasses] = useState([]);
  const [classSelected, setClass] = useState(null);
  const [periodSelected, setPeriod] = useState(null);
  const [partialSelected, setPartial] = useState(null);
  const [criteriaClasses, setCriteriaClasses] = useState([]);
  const [isLoadingCriterias, setIsLoadingCriterias] = useState(false);
  const [criteriaSelected, setCriteriaSelected] = useState(null);
  const [changingCriteria, setChangingCriteria] = useState(false);
  const [loadingStudentGrades, setLoadingStudentGrades] = useState(false);
  const [savingGrades, setSavingGrades] = useState(false);
  const [origin, setOrigin] = useState(false);
  const [sourceClassId, setSourceClassId] = useState(false);
  // const [filterForStudentGrades, setFilterForStudentGrades] = useState({});

  // Modals variables
  const [showChooseCopyOrNew, setShowChooseCopyOrNew] = useState(false);
  const [showAddCriterian, setShowAddCriterian] = useState(false);
  const [criterianForEdit, setCriterianForEdit] = useState({});
  const [showCopyCriterian, setShowCopyCriterian] = useState(false);
  const [showLeavePage, setShowLeavePage] = useState(false);
  const [scoreEquivalences, setScoreEquivalences] = useState([]);
  const [availableChanges, setAvailableChanges] = useState(false);

  // Monitor screen resizing
  window.addEventListener("resize", () => {
    setContainerHeight(window.innerHeight - 300);
  });
  /* Initial loading */
  useEffect(() => {
    if (!permissions.students_grades.access) {
      history.push("/dashboard");
    }
    Sentry.setTag("section", "Capture Grades View");
    // Get main filters
    getTeacherClasses();
  }, []);

  useEffect(() => {
    const filters = {
      selectedClass: classSelected,
      selectedPeriod: periodSelected,
      selectedPartial: partialSelected,
    };
    // Check if filter has all its values set to null
    if (Object.values(filters).every((val) => val === null)) {
      setIsLoading(false);
      return;
    }
    getStudentClassList(filters);
  }, [criterionList]);

  // Detect when the user tries to leave the view or refresh the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (availableChanges) {
        event.preventDefault();
        event.returnValue = ""; // This line is necessary for some browsers to show the confirmation dialog
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  /**
   * Description: Get all teacher classes
   */
  const getTeacherClasses = () => {
    GetTeacherClasses().then((response) => {
      if (response?.data) {
        const classes = response.data.map((item) => {
          const periods = item?.periods || [];
          return {
            label: item.class_title,
            id: item.id,
            score_config: item.score_config,
            periods: periods.map((period, index) => {
              return {
                ...period,
                id: period.grading_period_id,
                label: `${t("captureGrades:main.period")} ${index + 1}`,
                partials: period.partials?.map((partial, index) => {
                  return {
                    id: partial,
                    label: `${t("captureGrades:main.partial")} ${index + 1}`,
                  };
                }),
              };
            }),
          };
        });
        setAllClasses(classes);
        // Evaluate query parameters
        if (classes?.length) {
          evaluateParams(classes);
        } else {
          setIsLoading(false);
          // Clear query params from URL
          removeUrlParams([
            "classId",
            "periodId",
            "partialId",
            "addCriterias",
            "origin",
          ]);
        }
      }
    });
  };

  /**
   * Description: Function to assign values for the main selectors
   * @param {array} [classes=[]]
   */
  const evaluateParams = (classes = []) => {
    // Get URL parameter values
    const classId = getUrlParamValue("classId");
    const periodId = getUrlParamValue("periodId");
    const partialId = getUrlParamValue("partialId");
    const origin = getUrlParamValue("origin");

    // Find the selected class or assign the first one
    const selectedClass =
      classes.find((item) => item.id == classId) || classes[0];
    // Find the selected period or assign the first one
    const selectedPeriod =
      selectedClass?.periods?.find((item) => item.id == periodId) ||
      selectedClass?.periods?.[0];
    // Find the selected partial or assign the first one
    const selectedPartial =
      selectedPeriod?.partials?.find((item) => item.id == partialId) ||
      selectedPeriod?.partials?.[0];

    if (selectedClass.score_config.score_system === "customized") {
      setScoreEquivalences(selectedClass.score_config.equivalences);
    }
    // Update state
    setClass(selectedClass);
    setPeriod(selectedPeriod);
    setPartial(selectedPartial);
    setSourceClassId(classId);
    setOrigin(origin);

    // Clear query params from URL
    removeUrlParams(["classId", "periodId", "partialId", "origin"]);

    // Get the criteria of the selected class
    if (selectedClass?.id) {
      getCriterionList(selectedClass.id);
    }

    // GET student list with scores
    const filters = {
      selectedClass,
      selectedPeriod,
      selectedPartial,
    };
    getStudentClassList(filters);
  };

  /**
   * Description: Get the list of class criteria
   * @param {number} classId
   */
  const getCriterionList = (classId, filters = null) => {
    setLoadingStudentGrades(true);
    const payload = {
      class_id: classId,
    };
    setCriteriaSelected(null);
    setIsLoadingCriterias(true);
    GetCriteriasByClass(payload)
      .then((response) => {
        if (response?.data) {
          // Set criterias list
          const criterias = response.data || [];
          setCriterionList(criterias);
          // Validate the criteria
          const criteriaDefalt = checkCriterias(classId, criterias);
          if (filters && criteriaDefalt) {
            filters.criteriaSelected = criteriaDefalt;
          }
        }
      })
      .finally(() => {
        setIsLoadingCriterias(false);
        if (filters) {
          getStudentClassList(filters);
        }
      });
  };

  /**
   * Description: Function to check criterias data
   * @param {number} classId
   * @param {array} criterias
   */
  const checkCriterias = (classId, criterias) => {
    let criteriadefault = null;

    // Get the query param to know if you want to copy criteria
    const addCriterias = Boolean(getUrlParamValue("addCriterias"));
    const firstCriteriaId = getUrlParamValue("firstCriteriaId");
    const secondCriteriaID = getUrlParamValue("secondCriteriaID");
    // Clear query params from URL
    removeUrlParams(["addCriterias", "firstCriteriaId", "secondCriteriaID"]);
    if (criterias?.length) {
      // Validate if the class has 2nd level criteria
      const hasSecondCriterias = criterias.some(
        (criteria) => criteria.second_criteria?.length
      );
      // add id de primer criterio dentro del segundo para rastrearlo
      if (hasSecondCriterias) {
        for (const element of criterias) {
          if (element.second_criteria?.length) {
            for (const secondCriteria of element.second_criteria) {
              secondCriteria.first_criteria_id =
                element.grading_criterion_class_id;
            }
          }
        }
      }
      // Get list classes with criteria if they want to be copied
      // and if there are no 2nd level criteria in the class
      if (addCriterias && !hasSecondCriterias) {
        // Get teacher classes with criteria of the same school level
        getCriteriasClasses(classId);
      } else {
        setCriteriaClasses([]);
      }
      // Save the 1st criterion of the 2nd level or save the 1st criterion of the 1st level
      let criteriaSelected = criterias[0]?.second_criteria?.length
        ? criterias[0].second_criteria[0]
        : criterias[0];

      // validar si hay preseleccion de criterios
      if (firstCriteriaId) {
        let criteriapreselected = criterias.find(
          (criteria) => criteria.grading_criterion_class_id == firstCriteriaId
        );
        if (criteriapreselected && secondCriteriaID) {
          let secondcriteriapreselected =
            criteriapreselected.second_criteria.find(
              (criteria) =>
                criteria.grading_criterion_class_id == secondCriteriaID
            );
          if (secondcriteriapreselected) {
            criteriaSelected = secondcriteriapreselected;
          } else {
            criteriaSelected = criteriapreselected;
          }
        } else {
          criteriaSelected = criteriapreselected;
        }
      }
      setCriteriaSelected(criteriaSelected);
      criteriadefault = criteriaSelected;
    }
    return criteriadefault;
  };

  /**
   * Description: Get teacher classes only with criterias of the same school level
   * @param {number} classId
   */
  const getCriteriasClasses = (classId) => {
    const payload = {
      source_class_id: classId,
    };
    GetCriteriasClasses(payload).then((response) => {
      if (response?.data) {
        setCriteriaClasses(response.data);
        // Show copy modal when classes with criteria exist
        if (response.data?.length) {
          setShowChooseCopyOrNew(true);
        }
      }
    });
  };

  /* Interaction functions */

  /**
   * Description: Create a copy of the criteria to be modified
   * @param {number} position
   */
  const modifyEvaluationCriteria = (position) => {
    const criterian = JSON.parse(JSON.stringify(criterionList[position]));
    setShowAddCriterian(true);
    setCriterianForEdit(criterian);
  };

  /**
   * Description: Show or hide the first-level child criteria
   * @param {number} position
   */
  const showHideCriteria = (position) => {
    // Update the criterion list using the previous state
    setCriterionList((prevCriterionList) =>
      // Map over the previous list to create a new updated list
      prevCriterionList.map((criteria, index) => {
        // Check if the current criteria is the one we want to modify
        const isTargetCriteria = index === position;
        if (isTargetCriteria) {
          return {
            ...criteria,
            hideChild: !criteria.hideChild,
          };
        }
        return criteria;
      })
    );
  };

  /**
   * Calculate the cell width of the grades
   * @param {number} totalCells
   * @returns
   */
  const calculateCellWidth = (totalCells = 0) => {
    const minWidth = "150px";
    const width = document.getElementById("containerGrades")?.offsetWidth;
    const spacesAndTitle = 248;
    const result = (width - spacesAndTitle) / totalCells;
    return result <= 100 ? minWidth : result + "px";
  };

  /**
   *
   */
  const goBack = () => {
    if (origin == "classProfile") {
      goToHref(`/class/${sourceClassId}`);
    } else {
      history.push("/dashboard");
    }
  };

  /**
   * Description: Get the list of students with their grades
   * @param {*} filters
   */
  const getStudentClassList = (filters) => {
    const payload = {};
    setLoadingStudentGrades(true);
    if (filters.selectedClass) payload.class_id = filters.selectedClass.id;
    if (filters.selectedPeriod)
      payload.grading_period_id = filters.selectedPeriod.grading_period_id;
    if (filters.selectedPartial)
      payload.grading_period_partial_id = filters.selectedPartial.id;
    GetStudentClass(payload)
      .then((result) => {
        if (!result.data) return;
        const students = result?.data
          ? result.data.map((student) => JSON.parse(JSON.stringify(student)))
          : [];
        setStudentListCriterionList(students);
        const filteredStudentGrades = [];

        for (let student of result.data) {
          // Si tienen criterios de primer nivel
          if (filters.criteriaSelected) {
            // "Formatear con criterios"
            const gradingPeriod = student.grading_periods.find(
              (period) =>
                period.grading_period_id ==
                filters.selectedPeriod.grading_period_id
            );
            let gradingCriteria = null;
            if (filters.selectedPartial) {
              const gradingPartial = gradingPeriod.partials.find(
                (partial) =>
                  partial.grading_period_partial_id ==
                  filters.selectedPartial.id
              );

              gradingCriteria = gradingPartial.criteria.find(
                (criteria) =>
                  criteria.grading_criterion_class_id ==
                  filters.criteriaSelected.grading_criterion_class_id
              );
              // Buscar segundo criterio paralelo al primero
              if (!gradingCriteria && !gradingPartial.criteria) {
                gradingCriteria = gradingPartial.second_criterias.find(
                  (criteria) =>
                    criteria.grading_criterion_class_id ==
                    filters.criteriaSelected.grading_criterion_class_id
                );
              }
              // Buscar segundo criterio dentro del primero
              if (!gradingCriteria && gradingPartial.criteria.length > 0) {
                const firstCriteria = gradingPartial.criteria.find(
                  (criteria) =>
                    criteria.grading_criterion_class_id ==
                    filters.criteriaSelected.first_criteria_id
                );
                gradingCriteria = firstCriteria.second_criterias.find(
                  (criteria) =>
                    criteria.grading_criterion_class_id ==
                    filters.criteriaSelected.grading_criterion_class_id
                );
              }
            } else {
              gradingCriteria = gradingPeriod.criteria.find(
                (criteria) =>
                  criteria.grading_criterion_class_id ==
                  filters.criteriaSelected.grading_criterion_class_id
              );
              // Buscar segundo criterio paralelo al primero
              if (!gradingCriteria && !gradingPeriod.criteria) {
                gradingCriteria = gradingPeriod.second_criterias.find(
                  (criteria) =>
                    criteria.grading_criterion_class_id ==
                    filters.criteriaSelected.grading_criterion_class_id
                );
              }
              // Buscar segundo criterio dentro del primero
              if (!gradingCriteria && gradingPeriod.criteria.length > 0) {
                const firstCriteria = gradingPeriod.criteria.find(
                  (criteria) =>
                    criteria.grading_criterion_class_id ==
                    filters.criteriaSelected.first_criteria_id
                );
                gradingCriteria = firstCriteria.second_criterias.find(
                  (criteria) =>
                    criteria.grading_criterion_class_id ==
                    filters.criteriaSelected.grading_criterion_class_id
                );
              }
            }

            student.scores = {
              comment: gradingCriteria?.comment || null,
              comment_id: gradingCriteria?.comment_id || null,
              grading_period_id: gradingCriteria?.grading_period_id || null,
              score: gradingCriteria?.score || "",
              score_id: gradingCriteria?.grading_criterion_score_id || null,
            };

            filteredStudentGrades.push(student);
            continue;
          }

          // si tienen periodos y parciales
          if (filters.selectedPeriod && filters.selectedPartial) {
            // "Formatear con periodos y parciales"
            const gradingPeriod = student.grading_periods.find(
              (period) =>
                period.grading_period_id ==
                filters.selectedPeriod.grading_period_id
            );
            const gradingPartial = gradingPeriod.partials.find(
              (partial) =>
                partial.grading_period_partial_id == filters.selectedPartial.id
            );
            student.scores = {
              comment: gradingPartial?.comment || null,
              comment_id: gradingPartial?.comment_id || null,
              grading_period_id: gradingPartial?.grading_period_id || null,
              score: gradingPartial?.score || null,
              score_id: gradingPartial?.score_id || null,
            };
            filteredStudentGrades.push(student);
            continue;
          }

          // si solo tienen periodos
          if (filters.selectedPeriod) {
            // "Formatear con periodos"
            filters.selectedPeriod.id;
            const gradingPeriod = student.grading_periods.find(
              (period) => period.grading_period_id == filters.selectedPeriod.id
            );
            student.scores = {
              comment: gradingPeriod?.comment || null,
              comment_id: gradingPeriod?.comment_id || null,
              grading_period_id: gradingPeriod?.grading_period_id || null,
              score: gradingPeriod?.score || null,
              score_id: gradingPeriod?.score_id || null,
            };
            filteredStudentGrades.push(student);
            continue;
          }
        }
        setStudentsQualificationProcess(filteredStudentGrades);
      })
      .finally(() => {
        setIsLoading(false);
        setLoadingStudentGrades(false); //TODO:
      });
  };

  const changeScoreValue = (e, studentId) => {
    setAvailableChanges(true);
    const studentIndex = studentsQualificationProcess.findIndex(
      (student) => student.student_id === studentId
    );
    // insert new score
    if (studentIndex !== -1) {
      let newStudentsQualificationProcess = studentsQualificationProcess.map(
        (student) => JSON.parse(JSON.stringify(student))
      );
      if (classSelected.score_config.score_system === "customized") {
        const value = e.score_letter || "";
        const regexLetter = /[A-Za-z ñ]/;
        if (regexLetter.test(value) || value === "") {
          newStudentsQualificationProcess[studentIndex].newScore = true;
          newStudentsQualificationProcess[studentIndex].scores.score =
            value.toUpperCase();
        }
        setStudentsQualificationProcess(newStudentsQualificationProcess);
      } else {
        const value = e.target?.value || "";
        // numeric
        const regexInteger = /^([0-9]|[1-9][0-9]|100)$/;
        const regexDecimals =
          classSelected.score_config.grading_precision == "1"
            ? /^(\d{0,2}(\.\d{0,1})?|100(\.00?)?)$/
            : /^(\d{0,2}(\.\d{0,2})?|100(\.00?)?)$/;
        const regexNumeric =
          classSelected.score_config.grading_period_number_format === "integer"
            ? regexInteger
            : regexDecimals;
        if (regexNumeric.test(value) || value === "") {
          newStudentsQualificationProcess[studentIndex].newScore = true;
          newStudentsQualificationProcess[studentIndex].scores.score = value;
        }
        setStudentsQualificationProcess(newStudentsQualificationProcess);
      }
    }
  };

  const saveGradesForStudent = () => {
    setSavingGrades(true);
    /* Filter students with grades */
    const studentsWithScores = studentsQualificationProcess.filter(
      (student) => student.newScore
    );

    const onlyPeriods = !!(
      periodSelected &&
      !partialSelected &&
      !criteriaSelected
    );
    const onlyPartials = !!(
      periodSelected &&
      partialSelected &&
      !criteriaSelected
    );
    const criteriaInPartial = !!(
      periodSelected &&
      partialSelected &&
      criteriaSelected
    );
    const criteriaInPeriod = !!(
      periodSelected &&
      !partialSelected &&
      criteriaSelected
    );

    const payload = {};

    switch (true) {
      case onlyPeriods:
        payload.type = "period";
        payload.students_score = studentsWithScores.map((student) => {
          const newScore =
            student.scores.score != "" ? student.scores.score : null;
          return {
            student_class_id: student.student_class_id,
            grading_period_id: periodSelected.grading_period_id,
            // message:""
            scores: [
              {
                score_id: student.scores.score_id,
                score: newScore,
              },
            ],
          };
        });
        break;
      case onlyPartials:
        payload.type = "period_partial";
        payload.students_score = studentsWithScores.map((student) => {
          const newScore =
            student.scores.score != "" ? student.scores.score : null;
          return {
            student_class_id: student.student_class_id,
            grading_period_id: periodSelected.grading_period_id,
            grading_period_partial_id: partialSelected.id,
            // message: "",
            scores: [
              {
                score_id: student.scores.score_id,
                score: newScore,
              },
            ],
          };
        });
        break;
      case criteriaInPartial:
        payload.type = "period_partial_criterion";
        payload.grading_period_id = periodSelected.grading_period_id;
        payload.grading_period_partial_id = partialSelected.id;
        payload.students_score = studentsWithScores.map((student) => {
          const newScore =
            student.scores.score != "" ? student.scores.score : null;
          return {
            student_id: student.student_id,
            scores: [
              {
                grading_criterion_score_id: student.scores.score_id,
                grading_criterion_class_id:
                  criteriaSelected.grading_criterion_class_id,
                score: newScore,
              },
            ],
          };
        });
        break;
      case criteriaInPeriod:
        payload.type = "period_criterion";
        payload.organization_school_level_cycle_id = classSelected.id;
        payload.grading_period_id = periodSelected.grading_period_id;
        payload.students_score = studentsWithScores.map((student) => {
          const newScore =
            student.scores.score != "" ? student.scores.score : null;
          return {
            student_id: student.student_id,
            scores: [
              {
                grading_criterion_score_id: student.scores.score_id,
                grading_criterion_class_id:
                  criteriaSelected.grading_criterion_class_id,
                score: newScore,
              },
            ],
          };
        });
        break;
      default:
        break;
    }

    SaveStudentsScore(payload)
      .then(() => {})
      .finally(() => {
        setSavingGrades(false);
        let filters = {
          selectedClass: classSelected,
          selectedPeriod: periodSelected,
          selectedPartial: partialSelected,
        };
        if (criteriaSelected) {
          filters.criteriaSelected = criteriaSelected;
        }
        getStudentClassList(filters);
        setAvailableChanges(false);
      });
  };

  /**
   * Description: Function to show the grades of the header
   * @return {JSX.Element}
   */
  const headerGrades = () => {
    const header = studentList[0]?.grading_periods || [];
    let hasPartials = false;
    let hasCriteria = false;
    if (header.length > 0) {
      if (header[0].criteria) hasCriteria = true;
      if (header[0].partials) hasPartials = true;
      if (header[0].partials?.length > 0 && header[0].partials[0].criteria) {
        hasCriteria = true;
      }
    }
    const onlyOneColumn = [header[0]]; //FIXME: solo se muestra un periodo
    return onlyOneColumn.map((grade, pos) => {
      return (
        <Col
          key={pos}
          style={{
            width: calculateCellWidth(studentList[0]?.grading_periods.length),
            ...customStyles.criterionTitleBox,
          }}
          className="d-inline-block fw-bold"
        >
          <b className="mt-2">
            {hasCriteria && criteriaSelected
              ? criteriaSelected.name
              : hasPartials
              ? partialSelected?.label || "-"
              : periodSelected?.label || "-"}
          </b>
        </Col>
      );
    });
  };

  /**
   * Description: Function to show the grades of the body
   * @return {JSX.Element}
   */
  const bodyGrades = () => {
    const filteredStudentGrades = searchColumFocus();
    // iterar filteredStudentGrades y buscar por id de estudiante dentro de studentsQualificationProcess para obtener los scores
    if (studentsQualificationProcess.length > 0 && !changingCriteria) {
      for (let student of filteredStudentGrades) {
        const studentWithScores = studentsQualificationProcess.find(
          (studentWithScores) =>
            studentWithScores.student_id === student.student_id
        );
        if (studentWithScores && studentWithScores.newScore) {
          // studentWithScores.newScore = false;
          student.gradingForRender[0].score = studentWithScores.scores.score;
        }
      }
    }
    if (changingCriteria) setChangingCriteria(false);
    return filteredStudentGrades.map((student, index) => {
      return (
        <Row key={index}>
          <Col className="fw-bold" style={customStyles.studentNameBox}>
            <p className="mt-2" style={{ minWidth: "200px" }}>
              {" "}
              {student.student_name}
              {student.program_name
                ? ` - (${student.program_name}   ${student.program_level})`
                : ` - (${student.grade} ${student.group})`}
            </p>
          </Col>
          {student.gradingForRender.map((grade, pos) => {
            return (
              <Col
                key={pos}
                style={{
                  width: calculateCellWidth(
                    studentList[0]?.grading_periods.length
                  ),
                  ...customStyles.scoreBox,
                }}
                className="d-inline-block"
              >
                {classSelected.score_config.score_system === "customized" ? (
                  // crear dropdown con equivalencias
                  <Dropdown>
                    <Dropdown.Toggle
                      id="equivalences"
                      variant="outline-secondary"
                      // style={{ width: "75px", height: "25px" }}
                      className="mt-2"
                      disabled={savingGrades}
                    >
                      {grade.score || ""}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        minWidth: "fit-content",
                        zIndex: "1999 !important",
                      }}
                    >
                      <Dropdown.Item
                        className="removeElementDropdown"
                        onClick={() =>
                          changeScoreValue(
                            "",
                            student.student_id,
                            grade.score_id
                          )
                        }
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        X
                      </Dropdown.Item>
                      {scoreEquivalences.map((equivalence, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() =>
                              changeScoreValue(
                                equivalence,
                                student.student_id,
                                grade.score_id
                              )
                            }
                          >
                            {equivalence.score_letter}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Form.Control
                    name="score"
                    placeholder=""
                    value={grade?.score || ""}
                    className="mt-2"
                    onChange={(e) =>
                      changeScoreValue(e, student.student_id, grade.score_id)
                    }
                    style={{ width: "75px", height: "25px" }}
                    disabled={savingGrades}
                  />
                )}
              </Col>
            );
          })}
        </Row>
      );
    });
  };

  const searchColumFocus = () => {
    const filteredStudentGrades = [];
    const students = studentList.map((student) =>
      JSON.parse(JSON.stringify(student))
    );
    // clean studentsQualificationProcess
    if (changingCriteria) setStudentsQualificationProcess(students);
    for (let student of students) {
      if (criteriaSelected) {
        const gradingPeriod = student.grading_periods.find(
          (period) =>
            period.grading_period_id == periodSelected.grading_period_id
        );
        let gradingCriteria = null;
        if (partialSelected) {
          if (!gradingPeriod) return;
          const gradingPartial = gradingPeriod.partials.find(
            (partial) => partial.grading_period_partial_id == partialSelected.id
          );
          gradingCriteria = gradingPartial.criteria.find(
            (criteria) =>
              criteria.grading_criterion_class_id ==
              criteriaSelected.grading_criterion_class_id
          );
          // Buscar segundo criterio paralelo al primero
          if (!gradingCriteria && !gradingPartial.criteria) {
            gradingCriteria = gradingPartial.second_criterias.find(
              (criteria) =>
                criteria.grading_criterion_class_id ==
                criteriaSelected.grading_criterion_class_id
            );
          }
          // Buscar segundo criterio dentro del primero
          if (!gradingCriteria && gradingPartial.criteria.length > 0) {
            const firstCriteria = gradingPartial.criteria.find(
              (criteria) =>
                criteria.grading_criterion_class_id ==
                criteriaSelected.first_criteria_id
            );
            gradingCriteria = firstCriteria?.second_criterias.find(
              (criteria) =>
                criteria.grading_criterion_class_id ==
                criteriaSelected.grading_criterion_class_id
            );
          }
        } else {
          gradingCriteria = gradingPeriod.criteria.find(
            (criteria) =>
              criteria.grading_criterion_class_id ==
              criteriaSelected.grading_criterion_class_id
          );
          // Buscar segundo criterio paralelo al primero
          if (!gradingCriteria && !gradingPeriod.criteria) {
            gradingCriteria = gradingPeriod.second_criterias.find(
              (criteria) =>
                criteria.grading_criterion_class_id ==
                criteriaSelected.grading_criterion_class_id
            );
          }
          // Buscar segundo criterio dentro del primero
          if (!gradingCriteria && gradingPeriod.criteria.length > 0) {
            const firstCriteria = gradingPeriod.criteria.find(
              (criteria) =>
                criteria.grading_criterion_class_id ==
                criteriaSelected.first_criteria_id
            );
            gradingCriteria = firstCriteria.second_criterias.find(
              (criteria) =>
                criteria.grading_criterion_class_id ==
                criteriaSelected.grading_criterion_class_id
            );
          }
        }
        // leave only one grade record per student
        // student.grading_periods = [gradingCriteria];
        student.gradingForRender = [gradingCriteria];
        student.scores = {
          comment: gradingCriteria?.comment || null,
          comment_id: gradingCriteria?.comment_id || null,
          grading_period_id: gradingCriteria?.grading_period_id || null,
          score: gradingCriteria?.score || null,
          score_id: gradingCriteria?.grading_criterion_score_id || null,
        };
        filteredStudentGrades.push(student);
        continue;
      }

      // si tienen periodos y parciales
      if (periodSelected && partialSelected) {
        const gradingPeriod = student.grading_periods.find(
          (period) =>
            period.grading_period_id === periodSelected.grading_period_id
        );
        if (!gradingPeriod) return;
        const gradingPartial = gradingPeriod.partials.find(
          (partial) => partial.grading_period_partial_id === partialSelected.id
        );
        // leave only one grade record per student
        // student.grading_periods = [gradingPartial];
        student.gradingForRender = [gradingPartial];
        student.scores = {
          comment: gradingPartial?.comment || null,
          comment_id: gradingPartial?.comment_id || null,
          grading_period_id: gradingPartial?.grading_period_id || null,
          score: gradingPartial?.score || null,
          score_id: gradingPartial?.score_id || null,
        };
        filteredStudentGrades.push(student);
        continue;
      }

      //si solo tienen periodos
      if (periodSelected) {
        const gradingPeriod = student.grading_periods.find(
          (period) =>
            period.grading_period_id == periodSelected.grading_period_id
        );
        // leave only one grade record per student
        // student.grading_periods = [gradingPeriod];
        student.gradingForRender = [gradingPeriod];
        student.scores = {
          comment: gradingPeriod?.comment || null,
          comment_id: gradingPeriod?.comment_id || null,
          grading_period_id: gradingPeriod?.grading_period_id || null,
          score: gradingPeriod?.score || null,
          score_id: gradingPeriod?.score_id || null,
        };
        filteredStudentGrades.push(student);
        continue;
      }
    }
    return filteredStudentGrades;
  };

  /**
   * Description: display a waiting interface for student grades
   * @return {JSX.Element}
   */
  const lazyLoadingGrades = () => {
    return [1, 2, 3, 4].map((item, index) => {
      return (
        <Row className="table-lazyLoading" key={index}>
          <Col md={6} style={customStyles.studentNameBox}>
            <div></div>
          </Col>
          <Col className="fw-bold " style={customStyles.studentNameBox}>
            <div></div>
          </Col>
        </Row>
      );
    });
  };

  return (
    <Container fluid>
      {isLoading ? (
        // TODO: Crear skeleton loading para esta vista
        <LoadingExtraLink />
      ) : (
        <>
          <Row>
            <Col md={12}>
              <i className="bi bi-chevron-left"></i>
              <FormLabel
                role="button"
                onClick={() =>
                  availableChanges ? setShowLeavePage(true) : goBack()
                }
              >
                {t("global:breadcrumds.goBack")}
              </FormLabel>
            </Col>
          </Row>
          {/* Filters */}
          <Row>
            <Col md={4} className="mt-3">
              <h3> {t("captureGrades:main.title")}</h3>
            </Col>
            <Col md={8}>
              {/* partial */}
              <FormGroup
                className="ms-3 float-lg-end"
                style={{ display: "inline-block" }}
              >
                <FormLabel>{t("captureGrades:main.partial")}:</FormLabel>
                <Dropdown>
                  <Dropdown.Toggle
                    id="partial"
                    variant="outline-secondary"
                    style={{ minWidth: "150px" }}
                    className="text-start"
                    disabled={!periodSelected?.partials?.length}
                  >
                    {partialSelected?.label ||
                      t("captureGrades:main.noPartial")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowX: "hidden" }}
                  >
                    {periodSelected?.partials?.map((option) => {
                      return (
                        <Dropdown.Item
                          key={option.id}
                          onClick={() => {
                            if (availableChanges) {
                              let resultado = window.confirm(
                                t("captureGrades:main.unsavedDataAlert")
                              );
                              if (resultado === false) return;
                              setAvailableChanges(false);
                            }
                            setPartial(option);
                            let filters = {
                              selectedClass: classSelected,
                              selectedPeriod: periodSelected,
                              selectedPartial: option,
                            };
                            if (criteriaSelected) {
                              filters.criteriaSelected = criteriaSelected;
                            }
                            getStudentClassList(filters);
                          }}
                        >
                          {option.label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </FormGroup>
              {/* period */}
              <FormGroup
                className="ms-3 float-lg-end"
                style={{ display: "inline-block" }}
              >
                <FormLabel> {t("captureGrades:main.period")}:</FormLabel>
                <Dropdown>
                  <Dropdown.Toggle
                    id="period"
                    variant="outline-secondary"
                    style={{ minWidth: "150px" }}
                    className="text-start"
                  >
                    {periodSelected?.label || t("captureGrades:main.period")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowX: "hidden" }}
                  >
                    {classSelected?.periods.map((option) => {
                      return (
                        <Dropdown.Item
                          key={option.grading_period_class_id}
                          onClick={() => {
                            if (availableChanges) {
                              let resultado = window.confirm(
                                t("captureGrades:main.unsavedDataAlert")
                              );
                              if (resultado === false) return;
                              setAvailableChanges(false);
                            }
                            setPeriod(option);
                            setPartial(option.partials?.[0]);
                            let filters = {
                              selectedClass: classSelected,
                              selectedPeriod: option,
                              selectedPartial: option.partials?.[0],
                            };
                            if (criteriaSelected) {
                              filters.criteriaSelected = criteriaSelected;
                            }
                            getStudentClassList(filters);
                          }}
                        >
                          {option.label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </FormGroup>
              {/* class */}
              <FormGroup
                className="ms-3 float-lg-end"
                style={{ display: "inline-block" }}
              >
                <FormLabel>{t("captureGrades:main.class")}:</FormLabel>
                <Dropdown>
                  <Dropdown.Toggle
                    id="class"
                    variant="outline-secondary"
                    style={{ minWidth: "150px" }}
                    className="text-start"
                  >
                    {classSelected?.label || t("captureGrades:main.class")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowX: "hidden" }}
                  >
                    {allClasses.map((option) => {
                      return (
                        <Dropdown.Item
                          key={option.id}
                          onClick={() => {
                            if (
                              option.score_config.score_system == "customized"
                            ) {
                              setScoreEquivalences(
                                option.score_config.equivalences
                              );
                            }
                            setClass(option);
                            setPeriod(option.periods?.[0]);
                            setPartial(option.periods?.[0]?.partials?.[0]);
                            const filters = {
                              selectedClass: option,
                              selectedPeriod: option.periods?.[0],
                              selectedPartial:
                                option.periods?.[0]?.partials?.[0],
                            };
                            getCriterionList(option.id, filters);
                          }}
                        >
                          {option.label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </FormGroup>
            </Col>
          </Row>
          {/* CriterionList and grades */}
          <Row className="mt-4">
            {/* CriterionList */}
            <Col md={3} className="px-4">
              <Row>
                <Col
                  md={12}
                  style={{
                    height: containerHeight,
                    ...customStyles.baseContainers,
                  }}
                >
                  <Row className="mt-3">
                    <Col md={12} classNamee="fw-bold">
                      <h5> {t("captureGrades:main.evaluationCriteria")}</h5>
                    </Col>
                  </Row>
                  {/* List criteria */}
                  <Row className="px-3">
                    {isLoadingCriterias ? (
                      // TODO: Crear skeleton loading para este apartado
                      <LoadingTools />
                    ) : criterionList?.length ? (
                      criterionList.map((criterion, index) => {
                        const hasSecondCriteria =
                          criterion.second_criteria?.length > 0;
                        return (
                          <Col
                            key={index}
                            md={12}
                            style={
                              index == 0
                                ? customStyles.firstCriterion
                                : customStyles.criterionBox
                            }
                          >
                            <Row>
                              <Col
                                md={10}
                                className={`d-flex gap-2 align-items-center flex-wrap ${
                                  criterion.grading_criterion_class_id ===
                                  criteriaSelected.grading_criterion_class_id
                                    ? "list-item selected"
                                    : ""
                                }`}
                              >
                                <b
                                  className="text-ellipsis"
                                  style={{
                                    ...customStyles.brokenText,
                                    cursor: hasSecondCriteria
                                      ? "default"
                                      : "pointer",
                                  }}
                                  onClick={() => {
                                    if (!hasSecondCriteria) {
                                      if (availableChanges) {
                                        let resultado = window.confirm(
                                          t(
                                            "captureGrades:main.unsavedDataAlert"
                                          )
                                        );
                                        if (resultado === false) return;
                                      }
                                      setCriteriaSelected(criterion);
                                      setChangingCriteria(true);
                                    }
                                  }}
                                >
                                  {criterion.name +
                                    " - " +
                                    criterion.percentage +
                                    "%"}
                                </b>{" "}
                                <b
                                  className="text-danger"
                                  style={{
                                    cursor: hasSecondCriteria
                                      ? "pointer"
                                      : "default",
                                  }}
                                  onClick={() =>
                                    hasSecondCriteria && showHideCriteria(index)
                                  }
                                >
                                  {criterion.second_criteria.length +
                                    " " +
                                    criterion.name}
                                </b>
                              </Col>
                              <Col
                                md={2}
                                className="float-end text-end"
                                style={{ marginTop: "7px" }}
                                onClick={() => modifyEvaluationCriteria(index)}
                              >
                                <i
                                  className="bi bi-pencil-fill"
                                  role="button"
                                />
                              </Col>
                            </Row>
                            {/* Second level criteria block */}
                            {!criterion.hideChild &&
                              criterion.second_criteria?.map(
                                (secondCriteria, index) => {
                                  return (
                                    <Row
                                      key={index}
                                      className={`list-item c-pointer ${
                                        secondCriteria.grading_criterion_class_id ===
                                        criteriaSelected.grading_criterion_class_id
                                          ? "selected"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        if (availableChanges) {
                                          let resultado = window.confirm(
                                            t(
                                              "captureGrades:main.unsavedDataAlert"
                                            )
                                          );
                                          if (resultado === false) return;
                                          setAvailableChanges(false);
                                        }
                                        setCriteriaSelected(secondCriteria);
                                        setChangingCriteria(true);
                                      }}
                                    >
                                      <Col md={10} className="align-middle">
                                        <span
                                          className="d-inline-block mt-1 mb-1 text-ellipsis"
                                          style={customStyles.brokenText}
                                        >
                                          {secondCriteria.name +
                                            " - " +
                                            secondCriteria.percentage +
                                            "%"}
                                        </span>
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                          </Col>
                        );
                      })
                    ) : (
                      <span>{t("captureGrades:main.noCriterias")}</span>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* Grades */}
            <Col md={9} className="px-4">
              <Row>
                <Col
                  md={12}
                  id="containerGrades"
                  className="px-4"
                  style={{
                    height: containerHeight,
                    overflowX: "scroll",
                    ...customStyles.baseContainers,
                  }}
                >
                  <Row className="mt-3">
                    <Col
                      className="fw-bold"
                      style={customStyles.studentTitleNameBox}
                    >
                      <b
                        className="pt-2 d-inline-block"
                        style={{ width: "200px" }}
                      >
                        {t("captureGrades:main.studentName")}
                      </b>
                    </Col>
                    {loadingStudentGrades ? "" : headerGrades()}
                  </Row>
                  {loadingStudentGrades ? lazyLoadingGrades() : bodyGrades()}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Btn save */}
          <Row className="mt-4">
            <Col md={12}>
              <Button
                id="saveRatings"
                disabled={savingGrades}
                onClick={saveGradesForStudent}
                className="float-lg-end"
              >
                {savingGrades ? (
                  <>
                    {t("global:buttons.save")}
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      variant="white"
                      style={{
                        width: "0.81rem",
                        height: "0.81rem",
                        marginLeft: "3px",
                        borderWidth: "0.17em",
                      }}
                    />
                  </>
                ) : (
                  t("global:buttons.save")
                )}
              </Button>
            </Col>
          </Row>

          {/* Modals */}
          {showChooseCopyOrNew && (
            <ChooseCopyOrNew
              showChooseCopyOrNew={showChooseCopyOrNew}
              onHide={() => setShowChooseCopyOrNew(false)}
              setShowAddCriterian={setShowAddCriterian}
              setShowCopyCriterian={setShowCopyCriterian}
            />
          )}

          {showAddCriterian && (
            <AddCriterian
              showAddCriterian={showAddCriterian}
              onHide={() => {
                setShowAddCriterian(false);
                setCriterianForEdit({});
              }}
              criterianForEdit={criterianForEdit}
              classId={classSelected?.id}
              updateCriterias={setCriterionList}
              criteriaSelected={criteriaSelected}
              updateCriteriaSelected={setCriteriaSelected}
            />
          )}

          {showCopyCriterian && (
            <CopyCriterian
              showCopyCriterian={showCopyCriterian}
              onHide={() => setShowCopyCriterian(false)}
              setShowChooseCopyOrNew={setShowChooseCopyOrNew}
              criteriaClassesToCopy={criteriaClasses}
              classSelected={classSelected}
              setCriterionList={setCriterionList}
              updateCriteriaSelected={setCriteriaSelected}
            />
          )}
          {/** Modal Leave Page */}
          {showLeavePage && (
            <ModalSuccess
              showModalSuccess={showLeavePage}
              title={t("captureGrades:modalLeavePage.title")}
              message={t("captureGrades:modalLeavePage.message")}
              fnCancelButton={() => setShowLeavePage(false)}
              fnAcceptButton={goBack}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default CaptureGradesView;
CaptureGradesView.propTypes = {
  match: PropTypes.object,
};
