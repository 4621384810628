import axios from "axios";
import { validateErrorResponse, ErrorResponse } from "./AxiosExtension";
let axiosOptions = {};
const prefix = process.env.REACT_APP_PREFIX;
import Cookies from "js-cookie";

/**
 * Obtains a list of students
 * @param {obj} data
 * @returns {obj}
 */
export const getStudentsReduced = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/students/reduced`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return error;
  }
};

/**
 * Download template for importing materials
 */
export function DownloadTemplate() {
  const url = `${process.env.REACT_APP_REST_URL}/assets/csv/classes.csv`;
  window.open(url, "fullscreen=yes");
}

/**
 * Obtains a list of classes
 * @param {obj} data
 * @returns {obj}
 */
export const getClassList = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/classes/list_table`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Send csv file for importing classes
 * @param {obj} request
 * @returns
 */
export const importClassesCSV = async (request) => {
  let data = new FormData();
  data.append("file", request.file[0]);
  data.append("school_cycle_id", request.cycle);
  if (request.level) {
    data.append("organization_school_level_cycle_id", request.level);
  } else {
    data.append("program_id", request.program);
  }

  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
    "Content-Type": "multipart/form-data",
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/classes/import`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Service to remove classes
 * @param {object} request
 * @returns
 */
export const DeleteClasses = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = request;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/classes`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Puporse: Get Subjects
 * @param {*} values
 * @returns API Response
 */
export const GetSubjects = async (values) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/subjects`;
  axiosOptions.params = values;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Service to remove subject
 * @param {object} request
 * @returns
 */
export const DeleteSubjects = async (request) => {
  axiosOptions = {};
  axiosOptions.method = "delete";
  axiosOptions.data = request;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/subjects`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Puporse: Get color palette
 * @returns API Response
 */
export const GetColorsPalette = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/organization_school_levels/colors`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Purpose: Service to create a new subject
 * @argument {object} data //object containing the parameters for creating a new subject
 * @returns {JSON object} JSON object
 */
export const CreateSubject = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/subjects`;

  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to update the subject
 * @param {obj} data - object that has to update
 * @return {JSON object} JSON object
 */
export const UpdateSubject = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/subjects`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Puporse: Get students by parentId
 * @returns API Response
 */
export const GetStudentsParent = async () => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = {
    organization_id: Cookies.get(`cmOrganizationID${prefix}`),
    parent_id: Cookies.get(`cmUserID${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/students`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Bring the school cycles by student
 * @param {Object} data
 * @returns
 */
export const getSchoolCycleStudent = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/students_school_cycles`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to create multiple attendance for the students
 * @argument {Object} data
 * @returns {JSON object} JSON object
 */
export const CreateMultipleAttendance = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/classes/multiple_attendances`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to create a student score
 * @param {object} data
 * @return {JSON object} JSON object
 */
export const CreateStudentScore = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/classes/students_score`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to update a student score
 * @param {object} data
 * @return {JSON object} JSON object
 */
export const UpdateStudentScore = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "put";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/classes/students_score`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to create a student comment
 * @param {object} data
 * @return {JSON object} JSON object
 */
export const CreateStudentComment = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/classes/student_comment`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to create main grading criteria
 * @param {object} data
 * @return {JSON object} JSON object
 */
export const CreateMainGradingCriteria = async (data) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/evaluation_criteria/main_criteria`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to get main grading criteria
 * @param {object} data
 * @return {JSON object} JSON object
 */
export const GetMainGradingCriteria = async (data) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/evaluation_criteria/main_criteria`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to get teacher classes
 * @param {object} [data={}]
 * @return {JSON object} JSON object
 */
export const GetTeacherClasses = async (data = {}) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/dashboards/teacher_classes_list`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to get teacher classes only with criterias of the same school level
 * @param {object} [data={}]
 * @return {JSON object} JSON object
 */
export const GetCriteriasClasses = async (data = {}) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/evaluation_criteria/classes`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to get class criterias
 * @param {object} [data={}]
 * @return {JSON object} JSON object
 */
export const GetCriteriasByClass = async (data = {}) => {
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.params = data;
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/evaluation_criteria`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to create secondary grading criteria
 * @param {object} [data={}]
 * @return {JSON object} JSON object
 */
export const SaveEvaluationCriteria = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/evaluation_criteria`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

export const CopyClassCriteria = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/evaluation_criteria/copy_criteria`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to get student score
 * @param {*} data 
 * @returns 
 */
export const GetStudentClass = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "get";
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2_1/dashboards/student_class`;
  axiosOptions.params = data;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};

/**
 * Description: Service to create a student score
 * @param {object} data
 * @return {JSON object} JSON object
 */
export const SaveStudentsScore = async (data = {}) => {
  axiosOptions = {};
  axiosOptions.method = "post";
  axiosOptions.data = data;
  axiosOptions.headers = {
    Authorization: localStorage.getItem(`cmToken${prefix}`),
  };
  axiosOptions.url = `${process.env.REACT_APP_REST_URL}/v2/evaluation_criteria/students_score`;
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    validateErrorResponse(error.response);
    return ErrorResponse(error.response);
  }
};