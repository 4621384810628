/**
 * GlobalTools
 *
 * Purpose: Contains global functions and variables
 *
 * @author Marisol Torres <marisol@inclancommunications.com>
 * @date 27/11/2021 12:51:25 PM
 */

import React from "react";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import * as moment from "moment";

// import API Services
import { GetUserGetDebtsRestrictions } from "../../api/PaymentsReport";
import { GetSchoolCycle } from "../../api/Cycles";
import { organizationGet } from "../../api/Organizations";
import { userGet } from "../../api/Users";
const prefix = process.env.REACT_APP_PREFIX;
const platform = process.env.REACT_APP_PLATFORM_SHORT;
let imageOrganization = "";
var isLive =
  window.location.href.includes("//app.campusmovil.com") ||
  window.location.href.includes("//app2.campusmovil.com");

/**
 * Select component styles
 */
const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "'Mulish', sans-serif",
    padding: "5px 16px 3px 16px",
    color: state.isSelected ? "white" : state.isDisabled ? "#d4d4d4" : null,
    backgroundColor: state.isSelected
      ? "var(--base-color)"
      : state.isDisabled
      ? "white"
      : state.isFocused
      ? "var(--lightest-color)"
      : null,
    cursor: state.isDisabled ? "not-allowed" : null,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    zIndex: "9999 !important",
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "'Mulish', sans-serif",
    outline: "0 !important",
    border: state.isDisabled
      ? "1px solid #e6e6e6 !important"
      : state.isFocused
      ? "1px solid #ffb7c4 !important"
      : "1px solid #bfc2c6 !important",

    boxShadow: state.isFocused
      ? "0 0 0 0.25rem rgb(255 217 224) !important"
      : "none",
    borderRadius: "3px",
    backgroundColor: state.isDisabled
      ? "#f2f2f2"
      : state.isFocused
      ? "#ffffff !important"
      : "#ffffff",
  }),
  container: (provided) => ({
    ...provided,
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "'Mulish', sans-serif",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#d4d4d4",
      fontSize: "14px",
      fontFamily: "'Mulish', sans-serif",
    };
  },
};
export default selectStyles;

/**
 * Function to redirect to other page
 * @param {string} page URL to redirect
 */
export function goTo(page) {
  window.location = page;
}

/**
 * Purpose: Open specific routes
 * @param {String} page
 */
export function goToHref(page) {
  let url = process.env.REACT_APP_V1;
  window.location.href = `${url}${page}`;
}

/**
 * Purpose: Returns the number in thousands format
 * @param {*} number
 * @returns
 */
export function thousandsFormat(number) {
  number = parseFloat(number).toFixed(2);
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1,";
  let arr = number.toString().split(".");
  arr[0] = arr[0].replace(exp, rep);
  return arr[1] ? arr.join(".") : arr[0];
}

/**
 * Purpose: Returns the message in the specified language when no records were found in the select components
 * @returns
 */
export function noOptionsMessage() {
  let language = localStorage.getItem(`cmLanguage${prefix}`);
  if (language == "english") {
    return "No matches";
  } else {
    return "No hay coincidencias";
  }
}

/**
 * Purpose: Defines the message that is displayed while loading the options in the select
 * @returns
 */
export function loadingMessage() {
  let language = localStorage.getItem(`cmLanguage${prefix}`);
  if (language == "english") {
    return "Searching...";
  } else {
    return "Buscando...";
  }
}

/**
 * Purpose: Get/save organization's image from context
 * @param {String} img
 * @param {boolean} save
 * @returns img url
 */
export function imgOrganization(img, save) {
  if (save) {
    imageOrganization = React.createContext({ imgOrganizationUrl: img });
  } else {
    return imageOrganization;
  }
}

/**
 * Purpose: Get the domain cookies
 * @returns domain url
 */
export function domainCookie() {
  let domain = window.location.href.includes(".campusmovil.com")
    ? ".campusmovil.com"
    : window.location.hostname;
  return domain;
}

/**
 * Purpose: Get the expiration date for cookies
 * @returns date cookie
 */
export function dateCookie() {
  var now = new Date();
  now.setDate(now.getDate() + 5);
  return now;
}

/**
 * Purpose: Valid if the number of required fields is greater than the optional fields
 * @param {Object} initialValues
 * @param {Object} validateSchema
 * @returns
 */
export function isRequiredFields(initialValues, validateSchema) {
  let result =
    Object.keys(initialValues).length -
    Object.keys(validateSchema.fields).length;
  return Object.keys(validateSchema.fields).length <= result;
}

/**
 * Purpose: Used to identify which user will be given permission to see certain things.
 * @returns
 */

export function userInfo() {
  let info = {
    id: localStorage.getItem(`cmUserID${prefix}`),
    token: localStorage.getItem(`cmToken${prefix}`),
    isSuperAdmin: localStorage.getItem(`cmGroup${prefix}`) == 1,
    isAdmin: localStorage.getItem(`cmGroup${prefix}`) == 2,
    isParent: localStorage.getItem(`cmGroup${prefix}`) == 6,
    isStudent: localStorage.getItem(`cmGroup${prefix}`) == 8,
  };
  return info;
}

/**
 * nameCookieMenus
 * Purpose:
 * Get the cookie name for the HideMenus depending on the environment and platform type
 *
 * @author Angelica Martínez Feliciano <angie@inclancommunications.com>
 * @return {String} hideMenu
 */
export function nameCookieMenus() {
  let hideMenu = isLive ? "cmHideMenus" : "cmHideMenusBeta";

  return hideMenu;
}

/**
 * SetHideMenus
 * Purpose: Sets the current hideMenus value
 */
export function SetHideMenus(hide) {
  let name = nameCookieMenus();
  Cookies.set(name, hide, {
    path: "/",
    domain: domainCookie(),
    expires: dateCookie(),
  });
}

/*
 GetHideMenus
 Returns binary value
 */
export function GetHideMenus() {
  let name = nameCookieMenus();
  var hide = Cookies.get(name);
  return hide;
}

export function randomCode() {
  let code = "";
  let charactersByType = 2;
  //characters to be able to create a strong password
  let charactersType = {
    upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    lowerCase: "abcdefghijklmnopqrstuvwxyz",
    numbers: "0123456789",
    specialCharacters: "@$!%*#&",
  };
  for (let i = 0; i < charactersByType; i++) {
    for (let type in charactersType) {
      let posRandom = Math.floor(Math.random() * charactersType[type].length); //a random position of the string is generated according to the character type
      code += charactersType[type].substr(posRandom, 1); //a character is extracted according to random position and is assigned to code
    }
  }

  return code;
}

/**
 * Purpose: Define the name of the cookie according to the platform and the environment
 * @param {String} name Cookie Name
 * @returns Cookie Name
 */
export function nameCookie(name) {
  let nameToken = `${platform}${name}${prefix}`;
  return nameToken;
}

/**
 * Puporse: Get Organization Notification Token
 */
export function GetNotificationToken() {
  // Get Cookie Name
  const nameNotificationToken = nameCookie("NotificationToken");
  // Get Cookie
  const notificationToken = Cookies.get(nameNotificationToken);
  // Return Cookie
  return notificationToken;
}

/**
 * Puporse: Save Organization's Notification Token.
 * @param {String} token Notification token
 */
export function SetNotificationToken(token) {
  const name = nameCookie("NotificationToken");
  Cookies.set(name, token, {
    expires: dateCookie(),
    domain: domainCookie(),
  });
}

/**
 * Puporse: Get Organization Notification
 * @returns Organization id
 */
export function GetOrganizationNotification() {
  // Get cookie name
  const name = nameCookie("OrganizationNotification");
  // Get Organization ID
  const NotificationToken = Cookies.get(name);
  // Return Organization ID
  return NotificationToken;
}

/**
 * notifOrganizationExists
 * Purpose: firebase Organization indicator for notifications
 *
 * @param {bool} value
 * @author Mario Simbron Gonzalez <mario@inclancommunications.com>
 */
export function SetOrganizationNotification(value) {
  const name = nameCookie("OrganizationNotification");
  Cookies.set(name, value, {
    expires: dateCookie(),
    domain: domainCookie(),
  });
}

/**
 * Puporse: Get User Notification
 * @returns Organization id
 */
export function GetUserNotification() {
  // Get cookie name
  const name = nameCookie("UserNotification");
  // Get Organization ID
  const NotificationToken = Cookies.get(name);
  // Return Organization ID
  return NotificationToken;
}

/**
 * notifOrganizationExists
 * Purpose: firebase Organization indicator for notifications
 *
 * @param {bool} value
 * @author Mario Simbron Gonzalez <mario@inclancommunications.com>
 */
export function SetUserNotification(value) {
  const name = nameCookie("UserNotification");
  Cookies.set(name, value, {
    expires: dateCookie(),
    domain: domainCookie(),
  });
}

/**
 * Description: Function to get the value of a URL parameter
 * @param {string} paramSearch
 * @return {string|boolean} if it does not find it, it will be false
 */
export function getUrlParamValue(paramSearch) {
  //Get all the parameters of the url
  const urlParams = window.location.search;
  //Create the instance
  const allParams = new URLSearchParams(urlParams);
  //Get the value of the parameter to look for
  const paramValue = allParams.get(paramSearch) || false;
  return paramValue;
}

/**
 * Description: Function to remove parameters from the URL
 * @param {string | array} params
 */
export function removeUrlParams(params) {
  // Current path
  const url = new URL(window.location);
  // Ensure params is always an array
  const paramsToRemove = Array.isArray(params) ? params : [params];
  // Delete the specified parameters
  paramsToRemove.forEach((param) => url.searchParams.delete(param));
  // Update URL without reloading the page
  window.history.replaceState({}, "", url);
}

/**
 * Restriction param
 * Purpose: Get Restriction param from user´s session
 * @author Mario Simbron Gonzalez <mario@inclancommunications.com>
 */
export function GetUserRestriction() {
  // Get cookie name
  const name = nameCookie("UserPartialRestriction");
  // Get Organization ID
  const userRestriction = Cookies.get(name);
  // Return Organization ID
  return userRestriction;
}

/**
 * Restriction param
 * Purpose: SET Restriction param
 * @param {bool} value
 * @author Mario Simbron Gonzalez <mario@inclancommunications.com>
 */
export function setUserRestriction(value) {
  const name = nameCookie("UserPartialRestriction");
  Cookies.set(name, value, {
    expires: dateCookie(),
    domain: domainCookie(),
  });
}

/**
 * Description: Function to get information general the session
 *
 */
export function loadGlobalResources(CookieToken) {
  Sentry.setTag("section", "loadGlobalResources");
  const userId = localStorage.getItem(`cmUserID${prefix}`);

  console.info("Obtaining global resources 🔎");
  // Get User info
  userGet(userId, CookieToken).then((result) => {
    try {
      let data = result.data.data;
      // Save Cookies
      const nameServices = nameCookie("RolesIds");
      const groupsIds = data.groups.map((group) => group.id);
      localStorage.setItem(nameServices, JSON.stringify(groupsIds));
      console.info(
        "%c Get User info - complete 👍🏽",
        "background: #222; color: #bada55"
      );
    } catch (error) {
      console.error(
        "loadGlobalResources: User info could not be obtained",
        error
      );
    }
  });
  // Get organization info
  organizationGet(CookieToken).then((result) => {
    try {
      let data = result.data.data[0];
      let services = data.services;
      formatOrganizationServices(services);
    } catch (error) {
      console.error(
        "loadGlobalResources: Organization info could not be obtained",
        error
      );
    }
  });

  // Get group id
  const group = localStorage.getItem(`cmGroup${prefix}`);
  if (group == 6 || group == 8) {
    // Get userId
    const userID = localStorage.getItem(`cmUserID${prefix}`);
    const values = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      user_id: userID,
    };
    // Get User Debts restrictions
    GetUserGetDebtsRestrictions(values).then((data) => {
      if (data && data.data) {
        const hasDebtRestriction = data.data.data;
        // Save Cookies
        switch (hasDebtRestriction) {
          case "partial_restriction":
            setUserRestriction(true);
            SetHideMenus(0);
            break;
          case "total_restriction":
            SetHideMenus(1);
            setUserRestriction(false);
            //Url to know where to redirect to
            if (
              location.href.indexOf("/payments") == -1 &&
              location.href.indexOf("/user") == -1
            ) {
              goToHref("/payments");
            }
            break;
          default:
            setUserRestriction(false);
            break;
        }
        console.info(
          "%c Get User Debts restrictions - complete 👍🏽",
          "background: #222; color: #bada55"
        );
      } else {
        Sentry.captureException(Error(JSON.stringify(data)));
      }
    });
  }
  // Get Cycles
  localStorage.removeItem(`cycles${prefix}`);
  GetSchoolCycle().then((result) => {
    try {
      let data = result.data;
      for (let index = 0; index < data.length; index++) {
        data[index].label = data[index].school_cycle;
        data[index].value = data[index].id;
      }
      localStorage.setItem(`cycles${prefix}`, JSON.stringify(data));
    } catch (error) {
      console.error("loadGlobalResources: Cycles could not be obtained", error);
    }
  });
}

const formatOrganizationServices = (data) => {
  let services = {};
  for (let service of data) {
    switch (service.service_id) {
      case "1": {
        //SIE
        services.SIE = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "2": {
        //Website
        services.Website = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "3": {
        //Payments
        services.Payments = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "4": {
        //App
        services.App = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "5": {
        //Reviews
        services.Reviews = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "6": {
        //Chat
        services.Chat = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "7": {
        //DocuSing
        services.DocuSing = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "8": {
        //SCE
        services.SCE = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "9": {
        //CRM
        services.CRM = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "10": {
        //DigitalContent
        services.DigitalContent = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "11": {
        //GoogleForEducations
        services.GoogleForEducations = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
      case "12": {
        //SAP
        services.SAP = {
          id: service.service_id,
          active: service.premium == 0 ? false : true,
        };
        break;
      }
    }
  }
  localStorage.setItem(`${prefix}Services`, JSON.stringify(services));
};

/**
 * Set Cookie value
 * Purpose: SET Cookie value
 * @param {String} cookieName Cookie name
 * @param {String} value Cookie value
 * @author Mario Simbron Gonzalez <mario@inclancommunications.com>
 */
export function setCookie(cookieName, value) {
  const name = nameCookie(cookieName);
  Cookies.set(name, value, {
    expires: dateCookie(),
    domain: domainCookie(),
  });
}

/**
 * Description: Function to get the full name of a person through their data
 * @param {object} [person={}] //person object
 * @param {boolean} [bySurname=false] //sort full name by surname
 * @param {string} [separator] //separator
 * @return {String} full name of the person
 */
export function getFullName(person = {}, bySurname = false, separator = " ") {
  const firstName = person.first_name || "";
  const middleName = person.middle_name || "";
  const lastName = person.last_name || "";
  //To avoid adding a blank space when the middle name does not come
  const name = middleName ? `${firstName} ${middleName}` : firstName;
  //name ordering
  if (bySurname) {
    return `${lastName}${separator}${name}`;
  } else {
    return `${name}${separator}${lastName}`;
  }
}

/**
 * Description: Function to know if today is your birthday
 * @param {timestamp} birthDate
 * @return {boolean}
 */
export function itsMyBirthday(birthDate) {
  if (!birthDate || birthDate == "0") {
    return false;
  }
  const monthDayToday = moment().format("MM-DD");
  const monthDayBirtday = moment.unix(birthDate).utc().format("MM-DD");
  return monthDayBirtday == monthDayToday;
}

/**
 * Function to know if the organization is allowed to see the new features
 * @return {boolean}
 */
export function isNewFeaturesAvailable() {
  const organizationId = Cookies.get(`cmOrganizationID${prefix}`);
  const env = prefix || "Live";
  const organizations = [
    {
      environment: "Beta",
      organizationIds: ["126"],
    },
    {
      environment: "Staging",
      organizationIds: ["126"],
    },
    {
      environment: "Live",
      organizationIds: ["126"],
    },
  ];
  // find the corresponding object to the environment (Staging | Live | Beta)
  const environment = organizations.find(
    (organization) => organization.environment === env
  );
  // validates if the organization exists within the environment of valid organizations
  const showNewFeactures = environment.organizationIds.includes(organizationId);

  return showNewFeactures;
}

/**
 * Function to validate if the user has a specific role
 * @param {number or array} rolesToEvaluate required
 * @param {array} userRoles // Optional
 * @param {boolean} [exclude=false] // Optional
 * @param {string} [operator="||"] // Optional
 * @returns boolean
 */
export function validateUserRoleIds(
  rolesToEvaluate,
  userRoles,
  operator = "||",
  exclude = false
) {
  const nameServices = nameCookie("RolesIds");
  if (userRoles == null || userRoles == undefined)
    userRoles = JSON.parse(localStorage.getItem(nameServices));
  let isValid = false;
  if (rolesToEvaluate && userRoles) {
    if (exclude == true) {
      if (operator == "&&") {
        // Evaluate whether the user has all the roles
        if (Array.isArray(rolesToEvaluate)) {
          isValid = rolesToEvaluate.every((role) => {
            return !userRoles.includes(role.toString());
          });
        } else {
          isValid = !userRoles.includes(rolesToEvaluate.toString());
        }
      } else {
        if (Array.isArray(rolesToEvaluate)) {
          rolesToEvaluate.forEach((role) => {
            if (!userRoles.includes(role.toString())) {
              isValid = true;
            }
          });
        } else {
          if (!userRoles.includes(rolesToEvaluate.toString())) {
            isValid = true;
          }
        }
      }
    } else {
      if (Array.isArray(rolesToEvaluate)) {
        rolesToEvaluate.forEach((role) => {
          if (userRoles.includes(role.toString())) {
            isValid = true;
          }
        });
      } else {
        if (userRoles.includes(rolesToEvaluate.toString())) {
          isValid = true;
        }
      }
    }
  }
  return isValid;
}
