import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { GetUserRelativeStudent } from "../../api/DashboardRelativeStudent";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import * as moment from "moment";
import SeeAllModule from "./admin/SeeAllModule";
import { setUserRestriction } from "../global/GlobalTools";

const customStyles = {
  containerImg: {
    minHeight: "215px",
    textAlign: "center",
  },
  containerImgTeacher: {
    textAlign: "center",
  },
  styleImg: {
    height: "140px",
    width: "140px",
    border: "solid 1px white",
  },
  lazy: {
    height: "25px",
  },
  lazyImgStudent: {
    height: "140px",
    borderRadius: "100px",
    width: "140px",
    left: "75px",
  },
  lazyImgParent: {
    height: "140px",
    borderRadius: "100px",
    width: "140px",
    left: "15px",
  },
  lazyImgTeacher: {
    height: "83px",
    width: "83px",
    borderRadius: "100px",
  },
  lazyTitleTeacher: {
    height: "35px",
  },
  lazyTeacher: {
    height: "20px",
  },
};

const lazyLoadingStudent = () => {
  return (
    <div>
      <Row className="table-lazyLoading">
        <Col md={3}>
          <div style={customStyles.lazyImgStudent}></div>
        </Col>
        <Col md={5}>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-3">
        <Col md={2} className="offset-md-10">
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
    </div>
  );
};

const lazyLoadingParent = () => {
  return (
    <div>
      <Row className="table-lazyLoading">
        <Col md={3}>
          <div style={customStyles.lazyImgParent}></div>
        </Col>
        <Col md={5}>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-3">
        <Col md={2} className="offset-md-10">
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
    </div>
  );
};

const lazyLoadingTeacher = () => {
  return (
    <Row className="table-lazyLoading mt-2">
      <Col xs={3} sm={2}>
        <div style={customStyles.lazyImgTeacher}></div>
      </Col>
      <Col xs={9} sm={10}>
        <div style={customStyles.lazyTitleTeacher}></div>
        <div style={customStyles.lazyTeacher}></div>
        <div style={customStyles.lazyTeacher}></div>
      </Col>
    </Row>
  );
};

const UserInfo = (props) => {
  const [t] = useTranslation([
    "dashboard",
    "dashboardStudent",
    "organizations",
    "crm",
    "class",
    "reports",
    "modalCreateStudent",
  ]);
  const prefix = process.env.REACT_APP_PREFIX;
  const userGroup = Cookies.get(`cmGroup${prefix}`);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [userModule, setUserModule] = useState({});

  /**
   * Gets the parent/student user data
   */
  const userInfoProfile = () => {
    let request = {
      user_id: props.userId,
      // Set timezone
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    GetUserRelativeStudent(request)
      .then((result) => {
        if (result.status) {
          const userInfo = result.data;
          // Get list of students for when logged in as a pa
          if (props.setStudentList && userInfo.students.length) {
            props.setStudentList(userInfo.students);
            const filterDashboard = JSON.parse(
              localStorage.getItem(`cmFiltersDashboard${prefix}`)
            );
            props.setStudentSelected(
              (filterDashboard && filterDashboard.student) ||
                userInfo.students[0]
            );
            setUserModule(userInfo);
          }
          // Obtain user information when logged in with student
          if (userInfo.group_id == "8") {
            setUserModule(userInfo.students[0]);
            props.setIsHigherLevel(userInfo.students[0].program_id != null);
            props.setStudentId(userInfo.students[0].id);
          }
          // Get Restriction
          let hasDebtRestriction = false;
          const students = userInfo.students || [];
          if (students && Array.isArray(students) && students.length !== 0) {
            for (let item of students) {
              hasDebtRestriction =
                item &&
                item.has_debt_restriction &&
                item.has_debt_restriction == 1
                  ? true
                  : false;
              if (hasDebtRestriction) {
                break;
              }
            }
          }
          // Set Cookie
          props.setHasDebtRestriction &&
            props.setHasDebtRestriction(hasDebtRestriction);
          setUserRestriction(hasDebtRestriction);
          if (userInfo.group_id == "7") {
            setUserModule(userInfo);
          }
          //Storage of votes in localStorage
          localStorage.setItem(
            `cmUserVotes${prefix}`,
            JSON.stringify(userInfo.votes)
          );
          setLoadingInfo(false);
        }
      })
      .catch(() => {})
      .finally(() => setLoadingInfo(false));
  };

  const studentProfile = () => {
    if (loadingInfo) return lazyLoadingStudent();

    return (
      <>
        <Row>
          <Col md={3} style={customStyles.containerImg}>
            <img
              src={
                userModule.image_url
                  ? userModule.image_url
                  : "/img/student_placeholder.svg"
              }
              className="rounded-circle img-fluid mt-4"
              style={customStyles.styleImg}
            ></img>
          </Col>
          <Col md={9}>
            <Row>
              <b className="fs-3">{userModule.name}</b>
            </Row>
            <Row>
              <p>
                <b>{t("modalCreateStudent:labels.dateBirth")}: </b>
                {moment.unix(userModule.birth_date).utc().format("DD/MM/YYYY")}
              </p>
            </Row>
            <Row>
              <p>
                <b>{t("reports:headerTable.CURP")}: </b>
                {userModule.curp}
              </p>
            </Row>
            <Row>
              <p>
                <b>{t("organizations:modalCreate.email")}: </b>
                {userModule.email}
              </p>
            </Row>
            <Row>
              <Col md={1}>
                <p>
                  <b>{t("class:table.grade")}: </b>
                </p>
              </Col>
              <Col md={1}>
                <b>{t("class:table.group")}: </b>
              </Col>
              <Col md={2}>
                <p>
                  <b>{t("class:modalImport.level")}: </b>
                </p>
              </Col>
            </Row>

            <Row>
              <Col md={1}>
                <p>{userModule.grade}</p>
              </Col>
              <Col md={1}>
                <p>{userModule.group}</p>
              </Col>
              <Col md={2}>
                <p>{userModule.school_level}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-2">
            <SeeAllModule
              isCMv1={true}
              customText={t("dashboard:labels.viewProfile")}
              path={`/estudiante/${userModule.id}`}
            ></SeeAllModule>
          </Col>
        </Row>
      </>
    );
  };

  const parentProfile = () => {
    if (loadingInfo) return lazyLoadingParent();

    return (
      <>
        <Row>
          <Col md={3} style={customStyles.containerImg}>
            <img
              src={
                userModule.image_url
                  ? userModule.image_url
                  : "/img/student_placeholder.svg"
              }
              className="rounded-circle img-fluid mt-4"
              style={customStyles.styleImg}
            ></img>
          </Col>
          <Col md={9}>
            <Row>
              <b className="fs-3"> {userModule.name} </b>
            </Row>
            <Row>
              <p>
                <b>{t("organizations:modalCreate.email")}: </b>
                {userModule.email}
              </p>
            </Row>
            <Row>
              <p>
                <b>{t("crm:modalAddProspect.phoneNumer")}: </b>
                {userModule.phone}
              </p>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <SeeAllModule
              isCMv1={true}
              customText={t("dashboard:labels.viewProfile")}
              path={"/user"}
            ></SeeAllModule>
          </Col>
        </Row>
      </>
    );
  };

  const teacherProfile = () => {
    if (loadingInfo) return lazyLoadingTeacher();
    return (
      <Row className="mt-2">
        <Col xs={3} sm={2} style={customStyles.containerImgTeacher}>
          <img
            src={userModule.image_url || "/img/student_placeholder.svg"}
            className="rounded-circle"
            width={"83px"}
            height={"83px"}
          ></img>
        </Col>
        <Col xs={9} sm={10}>
          <Row>
            <b className="text-truncate fs-3">{`${userModule.name}`}</b>
          </Row>
          <Row>
            <Col>
              <b>{t("labels.level")}:</b> {t(`userRole.teacher`)}
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col sm={6} md={4} xxl={2}>
              <SeeAllModule
                isCMv1={true}
                customText={t("dashboard:labels.viewProfile")}
                path={"/user"}
              ></SeeAllModule>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  /**
   * Initial Loading
   */
  useEffect(() => {
    userInfoProfile();
  }, []);
  return (
    <Row>
      {userGroup == 7 ? (
        teacherProfile()
      ) : (
        <Col md={12} className="mt-3">
          {userGroup == 8 && studentProfile()}
          {userGroup == 6 && parentProfile()}
        </Col>
      )}
    </Row>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  userId: PropTypes.number,
  setStudentSelected: PropTypes.func,
  setStudentList: PropTypes.func,
  setIsHigherLevel: PropTypes.func,
  setHasDebtRestriction: PropTypes.func,
  setStudentId: PropTypes.func,
};
